<script setup>
const props = defineProps({
  modelValue: null,
  items: { default: [] },
  options: { default: {} },
});

const emit = defineEmits(["update:modelValue", "change"]);
const attrs = useAttrs();

const uniqid = getCurrentInstance().uid;
const selectedValue = ref(null);
const handleColorRef = computed(() => props.options.handleColor || "var(--text-color)");
const handleBgColorRef = computed(
  () => props.options.handleBgColor || "var(--background)"
);

function setValue(modelValue) {
  if (isEqual(selectedValue.value, modelValue)) return;

  selectedValue.value = modelValue;
}

function handleClick(modelValue) {
  setTimeout(() => {
    const updatedModelValue = getModelValue(modelValue);

    setValue(updatedModelValue);

    emit("update:modelValue", getReturnModelValue(modelValue));
    emit("change", getReturnModelValue(modelValue));
  });
}

function getModelLabel(item) {
  if (!item) return item;
  return attrs["item-label"] ? item[attrs["item-label"]] : item;
}

function getModelValue(item) {
  if (!item) return item;
  return attrs["item-value"] ? item[attrs["item-value"]] : item;
}

function getReturnModelValue(modelValue) {
  if (!modelValue) return modelValue;

  const isReturnObject = attrs["return-object"] !== undefined;

  if (isReturnObject) {
    const lookupModel = props.items.find((el) =>
      isEqual(getModelValue(el), getModelValue(modelValue))
    );
    return lookupModel;
  }

  return getModelValue(modelValue);
}

function isChecked(item) {
  const isReturnObject = attrs["return-object"] !== undefined;

  const itemValue = getModelValue(item);
  const modelValue = isReturnObject
    ? getModelValue(selectedValue.value)
    : selectedValue.value;

  return itemValue == modelValue;
}

function watchModel() {
  watch(
    () => props.modelValue,
    () => {
      setValue(props.modelValue);
    },
    { immediate: true }
  );
  watch(
    () => props.items,
    () => {
      if (!props.items || !props.items.length) return;

      const lookupModel = props.items.find((el) =>
        isEqual(getModelValue(el), getModelValue(props.modelValue))
      );
      const modelValue = props.modelValue || getModelValue(lookupModel);

      setValue(modelValue);
      emit("update:modelValue", getReturnModelValue(modelValue));
    },
    {
      deep: true,
    }
  );
}

onMounted(() => {
  watchModel();
});
</script>
<template>
  <div class="segmented-controls">
    <client-only>
      <template v-for="(item, index) in items">
        <input
          type="radio"
          :id="`option-${uniqid}.${index}`"
          :name="`segment-group-${uniqid}`"
          :checked="isChecked(item)"
        />

        <label
          :for="`option-${uniqid}.${index}`"
          @mousedown.prevent="handleClick(item, index)"
          >{{ transl(getModelLabel(item)) }}</label
        >
      </template>
    </client-only>
  </div>
</template>
<style scoped>
.segmented-controls {
  /* display: flex; */
  /* margin: 0 auto; */
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

/*
 * Vissualy hidden radiobuttons
 */
.segmented-controls input {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  z-index: 1;
  /* added line */
}

/* *:focus {
    outline: none;
    box-shadow: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    border: none;
} */

/*
 * Labels need to be the same size
 */
.segmented-controls label {
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  flex: 1 1 0px;
  position: relative;
  z-index: 1;
  transition: inherit;
}

/*
 * Adjust z-index of last label since that contains
 * the paddle that needs to go beneath all other labels
 */
.segmented-controls label:last-of-type {
  z-index: 0;
}

/*
 * Paddle
 */
.segmented-controls label:last-of-type::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  background-color: black;
  transition: inherit;
}

/*
 * Move paddle depending on which option is selected
 */
.segmented-controls input:nth-last-of-type(2):checked ~ label:last-of-type::after {
  transform: translateX(-100%);
}

.segmented-controls input:nth-last-of-type(3):checked ~ label:last-of-type::after {
  transform: translateX(-200%);
}

.segmented-controls input:nth-last-of-type(4):checked ~ label:last-of-type::after {
  transform: translateX(-300%);
}

.segmented-controls input:nth-last-of-type(5):checked ~ label:last-of-type::after {
  transform: translateX(-400%);
}
.segmented-controls input:nth-last-of-type(6):checked ~ label:last-of-type::after {
  transform: translateX(-500%);
}
.segmented-controls input:nth-last-of-type(7):checked ~ label:last-of-type::after {
  transform: translateX(-600%);
}
.segmented-controls input:nth-last-of-type(8):checked ~ label:last-of-type::after {
  transform: translateX(-700%);
}
/* .segmented-controls input:nth-last-of-type(9):checked ~ label:last-of-type::after {
	 transform: translateX(-800%);
}
 .segmented-controls input:nth-last-of-type(10):checked ~ label:last-of-type::after {
	 transform: translateX(-900%);
}
  */

/*
 * IOS 13 Theme
 * Special thanks to https://www.figma.com/community/file/768365747273056340
 */

/*
 * Container
 */
.segmented-controls {
  display: flex;
  /* display: grid; */
  border-radius: 0.5rem;
  background-color: var(--background-hover);
  color: var(--text-color);
  /* height: 2rem; */
  height: 2.5rem;
  padding: 0.125rem;
}

/*
 * Labels
 */
.segmented-controls label {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  line-height: 1;
}

/*
 * Dividers
 */
.segmented-controls label:not(:first-of-type)::before {
  content: "";
  position: absolute;
  z-index: -3;
  top: 0.5rem;
  left: 0;
  bottom: 0.5rem;
  width: 1px;
  background: rgba(0, 0, 0, 0.15);
  transition: inherit;
}

/*
 * Selected option
 */
.segmented-controls input:checked + label {
  /* font-weight: 600; */
  font-size: 14px;
  color: v-bind(handleColorRef);
}

/*
 * Hide dividers before and after the selected option
 */
.segmented-controls input:checked + label::before,
.segmented-controls input:checked + label + input + label::before {
  opacity: 0;
}

/*
 * Focus style for keyboard navigation
 */
.segmented-controls:focus-within {
  /* box-shadow: 0 0 0 .2rem rgba(0, 122, 255, 0.75); */
}

/*
 * Paddle
 */
.segmented-controls label:last-of-type::after {
  background: v-bind(handleBgColorRef);
  border-radius: 0.4275rem;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.04), 0px 3px 8px rgba(0, 0, 0, 0.12);
}
</style>
